import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
  step: 1,
  isLoading: false,
  info: { success: false, message: null },
  user: {},
  policies: []
}

export const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.SET_USER_INFO:
      return { ...state, isLoading: false, user: action.payload }

    case actionsTypes.SET_REGISTER_STEP:
      return { ...state, step: action.payload }

    case actionsTypes.SET_REGISTER_LOADING:
      return { ...state, isLoading: action.payload }

    case actionsTypes.SET_POLICIES:
      return { ...state, isLoading: false, policies: action.payload }

    case actionsTypes.SET_REGISTER_INFO:
      return { ...state, isLoading: false, info: { success: action.payload.success, message: action.payload.message } }

    default:
      return state
  }
}
