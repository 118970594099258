import React from 'react'
import './styles.scss'
import { dateLower, matchEmail, maxLength, minLength, required, validEmail } from '../../../utils/validators'
import { Control, Errors, Form } from 'react-redux-form'
import { connect } from 'react-redux'
import { setRegisterStep, setUserInfo } from '../../../redux/actions/ActionsCreators/registerActionsCreator'
import { trimString } from '../../../utils/Strings'

const RegisterForm = ({ updateStep, setUserInfo, registerFormState }) => {
  const identificationTypes = [
    { id: 'CC', value: 'Cédula de ciudadanía' },
    { id: 'TI', value: 'Tarjeta de identidad' },
    { id: 'CE', value: 'Cédula de extranjería' },
    { id: 'PT', value: 'Pasaporte' }
  ]

  const handleSubmit = (values) => {
    updateStep(2)
    const userInfo = { ...values, dni: trimString(values.dni), email: trimString(values.email) }
    setUserInfo(userInfo)
  }

  return (
    <Form onSubmit={(values) => handleSubmit(values)} model="registerForm" className="Form">
      <div className="Cols3">
        <div className="Col InputContainer">
          <label htmlFor="documentType">Tipo de documento</label>
          <Control.select model="registerForm.documentType" className="Input Select" name="documentType"
                          id="documentType" validators={{ required }}>
            <option value="null">Seleccionar</option>
            {identificationTypes.map(type => {return (<option key={type.id} value={type.id}>{type.value}</option>)})}
          </Control.select>
          <Errors className="TextError" model="registerForm.documentType" show="touched"
                  messages={{ required: 'El campo es requerido. ' }}/>
        </div>

        <div className="Col InputContainer">
          <label htmlFor="dni">Número de documento</label>
          <Control.text model="registerForm.dni" className="Input" name="dni" id="dni" placeholder="1234567890"
                        validators={{ required, minLength: minLength(6), maxLength: maxLength(12) }}/>
          <Errors className="TextError" model="registerForm.dni" show="touched"
                  messages={{
                    required: 'El campo es requerido. ',
                    minLength: 'El campo debe contener al menos 6 dígitos. ',
                    maxLength: 'El campo debe contener como máximo 12 dígitos. '
                  }}/>
        </div>

        <div className="Col InputContainer">
          <label htmlFor="expeditionDniDate">Fecha de expedición</label>
          <Control type="date" model="registerForm.expeditionDniDate" className="Input" name="expeditionDniDate"
                   id="expeditionDniDate" placeholder="01/01/2000" validators={{ required, dateLower }}/>
          <Errors className="TextError" model="registerForm.expeditionDniDate" show="touched"
                  messages={{
                    required: 'El campo es requerido. ',
                    dateLower: 'La fecha debe ser menor o igual a la fecha actual'
                  }}/>
        </div>
      </div>

      <div className="Cols3">
        <div className="Col InputContainer">
          <label htmlFor="expeditionDniPlace">Lugar de expedición</label>
          <Control.text model="registerForm.expeditionDniPlace" className="Input" name="expeditionDniPlace"
                        id="expeditionDniPlace" placeholder="Medellín"
                        validators={{ required, minLength: minLength(5), maxLength: maxLength(50) }}/>
          <Errors className="TextError" model="registerForm.expeditionDniPlace" show="touched"
                  messages={{
                    required: 'El campo es requerido. ',
                    minLength: 'El campo debe contener al menos 5 dígitos. ',
                    maxLength: 'El campo debe contener como máximo 50 dígitos. '
                  }}/>
        </div>

        <div className="Col InputContainer">
          <label htmlFor="email">Email:</label>
          <Control.text model="registerForm.email" className="Input" name="email" id="email"
                        placeholder="nombre@dominio.com" onCopy={(event) => {event.preventDefault()}}
                        onPaste={(event) => event.preventDefault()} autoComplete="off"
                        validators={{ required, validEmail }}/>
          <Errors className="TextError" model="registerForm.email" show="touched"
                  messages={{
                    required: 'El campo es requerido. ',
                    validEmail: 'El campo debe contener un email válido. '
                  }}/>
        </div>

        <div className="Col InputContainer">
          <label htmlFor="emailVerify">Confirmar email:</label>
          <Control.text model="registerForm.emailVerify" className="Input" name="emailVerify"
                        id="emailVerify" placeholder="nombre@dominio.com" onCopy={(event) => {event.preventDefault()}}
                        onPaste={(event) => event.preventDefault()} autoComplete="off"
                        validators={{ required, validEmail, matchEmail: matchEmail(registerFormState.email) }}/>
          <Errors className="TextError" model="registerForm.emailVerify" show="touched"
                  messages={{
                    required: 'El campo es requerido. ',
                    validEmail: 'El campo debe contener un email válido. ',
                    matchEmail: 'Los emails ingresados deben coincidir. '
                  }}/>
        </div>
      </div>

      <div className="ButtonsContainer">
        <Control.button model="registerForm" type="reset" className="BtnAdvice"> Restaurar</Control.button>
        <Control.button model="registerForm" type="submit" className="BtnConfirm">Continuar</Control.button>
      </div>
    </Form>
  )
}

const mapStateToProps = state => {
  return {
    registerFormState: state.registerForm
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateStep: (step) => dispatch(setRegisterStep(step)),
  setUserInfo: (user) => dispatch(setUserInfo(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
