import React from 'react'
import './styles.scss'
import { Control, Errors, Form } from 'react-redux-form'
import { dateGreaterThan, isNumber, maxLength, minLength, required } from '../../../utils/validators'
import { putUserInfo } from '../../../redux/actions/ActionsCreators/updateActionsCreators'
import { connect } from 'react-redux'

const UpdateForm = ({ authState, putUserInfo }) => {
  const handleSubmit = (values) => {
    const userInfo = { ...values, birthday: new Date(values.birthday) }
    putUserInfo(userInfo, authState.token)
  }

  return (
    <div className="UpdateContainer">
      <Form onSubmit={(values) => handleSubmit(values)} model="updateForm" className="Form">
        <div className="Cols3">
          <div className="Col InputContainer">
            <label htmlFor="firstName">* Nombres</label>
            <Control.text model="updateForm.firstName" className="Input" name="firstName"
                          id="firstName" placeholder="Nombre Nombre"
                          validators={{ required, minLength: minLength(2), maxLength: maxLength(50) }}/>
            <Errors className="TextError" model="updateForm.firstName" show="touched"
                    messages={{
                      required: 'El campo es requerido. ',
                      minLength: 'El campo debe contener al menos 5 dígitos. ',
                      maxLength: 'El campo debe contener como máximo 50 dígitos. '
                    }}/>
          </div>

          <div className="Col InputContainer">
            <label htmlFor="lastName">* Apellidos</label>
            <Control.text model="updateForm.lastName" className="Input" name="lastName"
                          id="lastName" placeholder="Apellido Apellido"
                          validators={{ required, minLength: minLength(2), maxLength: maxLength(50) }}/>
            <Errors className="TextError" model="updateForm.lastName" show="touched"
                    messages={{
                      required: 'El campo es requerido. ',
                      minLength: 'El campo debe contener al menos 5 dígitos. ',
                      maxLength: 'El campo debe contener como máximo 50 dígitos. '
                    }}/>
          </div>

          <div className="Col InputContainer">
            <label htmlFor="mobileNumber">* Teléfono de celular</label>
            <Control.text model="updateForm.mobileNumber" className="Input" name="mobileNumber"
                          id="mobileNumber" placeholder="3012654789"
                          validators={{ required, isNumber, minLength: minLength(10), maxLength: maxLength(10) }}/>
            <Errors className="TextError" model="updateForm.mobileNumber" show="touched"
                    messages={{
                      required: 'El campo es requerido. ',
                      isNumber: 'El campo debe contener un número. ',
                      minLength: 'El campo debe contener 10 dígitos. ',
                      maxLength: 'El campo debe contener 10 dígitos. '
                    }}/>
          </div>
        </div>

        <div className="Cols3">
          <div className="Col InputContainer">
            <label htmlFor="optionalNumber">Teléfono secundario o fijo</label>
            <Control.text model="updateForm.optionalNumber" className="Input" name="optionalNumber"
                          id="optionalNumber" placeholder="1234567890"
                          validators={{ isNumber, maxLength: maxLength(10) }}/>
            <Errors className="TextError" model="updateForm.optionalNumber" show="touched"
                    messages={{
                      isNumber: 'El campo debe contener un número. ',
                      minLength: 'El campo debe contener al menos 5 dígitos. ',
                      maxLength: 'El campo debe contener como máximo 10 dígitos. '
                    }}/>
          </div>

          <div className="Col InputContainer">
            <label htmlFor="address">* Dirección</label>
            <Control.text model="updateForm.address" className="Input" name="address"
                          id="address" placeholder="Calle 1 #2 - 03"
                          validators={{ required, minLength: minLength(5), maxLength: maxLength(50) }}/>
            <Errors className="TextError" model="updateForm.address" show="touched"
                    messages={{
                      required: 'El campo es requerido. ',
                      minLength: 'El campo debe contener 5 caracteres. ',
                      maxLength: 'El campo debe contener 50 caracteres. '
                    }}/>
          </div>

          <div className="Col InputContainer">
            <label htmlFor="birthday">* Fecha de nacimiento</label>
            <Control type="date" model="updateForm.birthday" className="Input" name="birthday"
                     id="birthday" placeholder="01/01/2000"
                     validators={{ required, dateLower: dateGreaterThan(12) }}/>
            <Errors className="TextError" model="updateForm.birthday" show="touched"
                    messages={{
                      required: 'El campo es requerido. ',
                      dateLower: 'El usuario debe ser mayor o igual de 12 años'
                    }}/>
          </div>
        </div>

        <div className="ButtonsContainer">
          <Control.button model="updateForm" type="reset" className="BtnAdvice">Restaurar</Control.button>
          <Control.button model="updateForm" type="submit" className="BtnConfirm">Continuar</Control.button>
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    authState: state.auth,
    updateState: state.update
  }
}

const mapDispatchToProps = (dispatch) => ({
  putUserInfo: (user, token) => dispatch(putUserInfo(user, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateForm)
