export const dataUriToBlob = (dataUri) => {
  const splitDataUri = dataUri.split(',')
  const byteString = splitDataUri[0].indexOf('base64') >= 0 ? atob(splitDataUri[1]) : decodeURI(splitDataUri[1])
  const mimeString = splitDataUri[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) { ia[i] = byteString.charCodeAt(i) }

  return new Blob([ia], { type: mimeString })
}
