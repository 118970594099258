import React, { useEffect, useState } from 'react'
import 'react-html5-camera-photo/build/css/index.css'
import './styles.scss'
import { connect } from 'react-redux'
import OnlineCamera from '../OnlineCamera'
import { setImage, setOption } from '../../../redux/actions/ActionsCreators/imageActionsCreator'
import { postUserImage, setUpdateInfo } from '../../../redux/actions/ActionsCreators/updateActionsCreators'
import ImagePreview from '../ImagePreview'

const ImageLoader = ({
  authState,
  imageState,
  updateState,
  setOption,
  setImage,
  setUpdateInfo,
  postUserImage
}) => {
  const [metadata, setImageMetadata] = useState(null)
  const [errorCamera, setErrorCamera] = useState(false)

  useEffect(() => {
    setImageMetadata(imageState.equivalences.filter(item => item.status === updateState.user.registerState)[0])
  }, [imageState, updateState, setOption, setImage, setUpdateInfo, metadata])

  const handleSnapshot = (snapshot) => {
    setOption(1)
    setImage(snapshot)
  }

  const handleError = () => {
    handleResetOptionValues(1)
    setErrorCamera(true)
    const cameraNotFound = 'No ha sido posible activar la cámara. por favor intente cargando los archivos'
    setUpdateInfo({ success: false, message: cameraNotFound })
  }

  const handleOpenCamera = () => {
    handleResetOptionValues(2)
  }

  const handleFileUpload = (event) => {
    handleResetOptionValues(1)
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      event = null
      setImage(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleFileInputClick = (event) => {
    event.target.value = null
  }

  const handleResetOptionValues = (option) => {
    setOption(option)
    setImage(null)
    setUpdateInfo({ success: false, message: null })
  }

  const handleSendImage = () => {
    handleResetOptionValues(1)
    postUserImage(updateState.user.dni, imageState.image, authState.token, metadata)
  }

  return (
    <div className="ImageLoaderContainer">
      <div className="ImageContainer">
        {imageState.option === 1 || imageState.image ?
          <ImagePreview image={imageState.image} metadata={metadata}/> : null}
        {imageState.option === 2 ? <OnlineCamera onSnapshot={handleSnapshot} onError={handleError}/> : null}
      </div>

      <div className="ButtonsContainer">
        <label className="BtnConfirm">
          Cargar archivo
          <input type="file" accept="image/*" onClick={handleFileInputClick} onChange={handleFileUpload}/>
        </label>

        {!errorCamera ? <button className="BtnConfirm" onClick={handleOpenCamera}>Usar cámara</button> : null}
        {imageState.image ? <button onClick={handleSendImage} className="BtnConfirm">Enviar</button> : null}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    authState: state.auth,
    imageState: state.image,
    updateState: state.update
  }
}

const mapDispatchToProps = (dispatch) => ({
  setOption: (option) => dispatch(setOption(option)),
  setImage: (data) => dispatch(setImage(data)),
  setUpdateInfo: (message) => dispatch(setUpdateInfo(message)),
  postUserImage: (dni, image, token, metadata) => dispatch(postUserImage(dni, image, token, metadata))
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageLoader)

