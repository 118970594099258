// Auth actions
export const SET_AUTH_LOADING = 'SHOW_AUTH_LOADING'
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const SET_AUTH_INFO = 'SET_AUTH_INFO'

// Register actions
export const SET_REGISTER_STEP = 'UPDATE_REGISTER_STEP'
export const SET_POLICIES = 'SET_POLICIES'
export const SET_REGISTER_LOADING = 'SHOW_REGISTER_LOADING'
export const SET_REGISTER_INFO = 'SET_REGISTER_INFO'

// Update actions
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_USER_PENALTIES = 'SET_USER_PENALTIES'
export const SET_UPDATE_LOADING = 'SET_UPDATE_LOADING'
export const SET_UPDATE_INFO = 'SET_UPDATE_INFO'
export const SET_UPDATE_STEP = 'SET_UPDATE_STEP'

// Images actions
export const SET_OPTION = 'SET_OPTION'
export const SET_IMAGE = 'SET_IMAGE'
export const SET_VALIDITY = 'SET_VALIDITY'

// Verify actions
export const SET_VERIFY_LOADING = 'SET_RESTORE_LOADING'
export const SET_VERIFY_INFO = 'SET_RESTORE_INFO'
export const SET_CERTIFICATE_FILE = 'SET_CERTIFICATE_FILE'

// Forget actions
export const SET_FORGET_LOADING = 'SET_FORGET_LOADING'
export const SET_FORGET_INFO = 'SET_FORGET_INFO'

// Captcha actions
export const SET_RECAPTCHA_TOKEN = 'SET_RECAPTCHA_TOKEN'
