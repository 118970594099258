import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
  step: null,
  isLoading: false,
  info: { success: false, message: null },
  user: null,
  penalties: []
}

export const updateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.SET_UPDATE_STEP:
      return { ...state, step: action.payload }

    case actionsTypes.SET_USER_INFO:
      return { ...state, isLoading: false, user: action.payload }

    case actionsTypes.SET_USER_PENALTIES:
      return { ...state, isLoading: false, penalties: action.payload }

    case actionsTypes.SET_UPDATE_LOADING:
      return { ...state, isLoading: action.payload }

    case actionsTypes.SET_UPDATE_INFO:
      return { ...state, isLoading: false, info: { success: action.payload.success, message: action.payload.message } }

    default:
      return state
  }
}
