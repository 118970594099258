import React from 'react'
import './styles.scss'
import { maxLength, minLength, required, validEmail } from '../../../utils/validators'
import { Control, Errors, Form } from 'react-redux-form'
import { connect } from 'react-redux'
import { forgetPassword } from '../../../redux/actions/ActionsCreators/forgetActionsCreator'
import Recaptcha from '../../../components/Recaptcha'
import { trimString } from '../../../utils/Strings'

const ForgetForm = ({ recaptchaState, forgetPassword }) => {
  const handleSubmit = (values) => {
    const userInfo = { ...values, dni: trimString(values.dni), email: trimString(values.email) }
    forgetPassword(userInfo, recaptchaState.token)
  }

  return (
    <Form onSubmit={(values) => handleSubmit(values)} model="forgetForm" className="Form">
      <div className="Cols3">
        <div className="Col InputContainer">
          <label htmlFor="dni">Número de documento</label>
          <Control.text model="forgetForm.dni" className="Input" name="dni" id="dni" placeholder="1234567890"
                        validators={{ required, minLength: minLength(6), maxLength: maxLength(12) }}/>
          <Errors className="TextError" model="forgetForm.dni" show="touched"
                  messages={{
                    required: 'El campo es requerido. ',
                    isNumber: 'El campo debe contener un número. ',
                    minLength: 'El campo debe contener al menos 6 dígitos. ',
                    maxLength: 'El campo debe contener como máximo 12 dígitos. '
                  }}/>
        </div>

        <div className="Col InputContainer">
          <label htmlFor="email">Email:</label>
          <Control.text model="forgetForm.email" className="Input" name="email" id="email"
                        placeholder="nombre@dominio.com" onCopy={(event) => {event.preventDefault()}}
                        onPaste={(event) => event.preventDefault()} autoComplete="off"
                        validators={{ required, validEmail }}/>
          <Errors className="TextError" model="forgetForm.email" show="touched"
                  messages={{
                    required: 'El campo es requerido. ',
                    validEmail: 'El campo debe contener un email válido. '
                  }}/>
        </div>

        <div className="Col Buttons">
          <Control.button model="forgetForm" type="reset" className="BtnAdvice">Restaurar</Control.button>
          <Control.button model="forgetForm" type="submit" className="BtnConfirm">Recuperar</Control.button>
        </div>
      </div>
      <Recaptcha/>
    </Form>
  )
}

const mapStateToProps = state => {
  return { recaptchaState: state.recaptcha }
}

const mapDispatchToProps = (dispatch) => ({
  forgetPassword: (values, token) => dispatch(forgetPassword(values, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgetForm)
