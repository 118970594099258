import Navbar from '../Navbar'
import { Loader } from '../Loader'
import Footer from '../Footer'
import React from 'react'
import './styles.scss'

const CommonBody = ({ children, isLoading }) => {
  return (
    <div className="BodyContainer">
      <div className="NavbarContainer">
        <Navbar showHeader={false} showHome={true}/>
      </div>
      <Loader isLoading={isLoading}/>

      <div className="BodyContentContainer">
        {children}
      </div>

      <div className="FooterContainer">
        <Footer/>
      </div>
    </div>
  )
}

export default CommonBody
