import React, { useEffect } from 'react'
import './styles.scss'

import { connect } from 'react-redux'
import {
  getDataPolicies,
  postUser, setRegisterInfo,
  setRegisterStep
} from '../../../redux/actions/ActionsCreators/registerActionsCreator'

const PoliciesForm = ({ registerState, getDataPolicies, updateStep, postUser, setRegisterInfo }) => {
  useEffect(() => {
    getDataPolicies()
  }, [getDataPolicies])

  const handleFinishRegistration = () => {
    const user = { ...registerState.user }
    user.acceptedPolicies = registerState.policies.map(policy => policy.id)
    user.expeditionDniDate = new Date(user.expeditionDniDate)
    postUser(user)
  }

  const handleCancelClick = () => {
    updateStep(1)
    setRegisterInfo({ success: false, message: null })
  }

  const formatPolicyType = (type) => {
    return {
      'DECLARACION_JURAMENTADA': 'Declaración Juramentada',
      'DATOS_PERSONALES': 'Política de tratamiento datos personales'
    }[type]
  }

  const renderPolicies = () => {
    const policies = registerState.policies
    return policies.map(policy => {
      return <div key={policy.id} className="PoliciesListContainer">
        <p className="Subtitle2">{formatPolicyType(policy.policyType)}</p>
        <p className="PolicyText">{policy.content}</p>
      </div>
    })
  }

  return (
    <div className="PoliciesContainer">
      {renderPolicies()}
      <b>
        Al hacer clic sobre el botón "FINALIZAR" manifiesto que acepto la "Declaración juramentada" y la
        "Política de tratamiento datos personales" para el desarrollo del curso virtual de convivencia.
      </b>
      <div className="ButtonsContainer">
        <button onClick={() => handleCancelClick()} className="BtnAdvice"> Cancelar</button>
        <button onClick={() => handleFinishRegistration()} className="BtnConfirm">Finalizar</button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    registerState: state.register
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDataPolicies: () => dispatch(getDataPolicies()),
  updateStep: (step) => dispatch(setRegisterStep(step)),
  postUser: (user) => dispatch(postUser(user)),
  setRegisterInfo: (info) => dispatch(setRegisterInfo(info))
})

export default connect(mapStateToProps, mapDispatchToProps)(PoliciesForm)
