export const anonymizeString = (string) => {
  let newString = ''
  string.split('').forEach((char, index) => {
    newString += (index < 2 || index >= (string.length - 2)) ? char : '*'
  })

  return newString
}

export const trimString = (string) => {
  return string.replace(/\s/g, '')
}
