import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setRecaptchaToken } from '../../redux/actions/ActionsCreators/recaptchaActionsCreator'

const Recaptcha = ({ setRecaptchaToken }) => {
  const recaptchaSiteKey = '6LfCl9IZAAAAAByi13rTm6y5fba7LAKUBbf5RKbf'

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`
    script.addEventListener('load', () => {
      window.grecaptcha.ready((_) => {
        window.grecaptcha
          .execute(recaptchaSiteKey, { action: 'submit' })
          .then((token) => {
            setRecaptchaToken(token)
          })
      })
    })
    document.body.appendChild(script)
  }, [setRecaptchaToken])

  return (
    <div
      className="g-recaptcha"
      data-sitekey={recaptchaSiteKey}
      data-size="invisible"
    />
  )
}

const mapDispatchToProps = (dispatch) => ({
  setRecaptchaToken: (token) => dispatch(setRecaptchaToken(token))
})

export default connect(null, mapDispatchToProps)(Recaptcha)
