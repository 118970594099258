import React from 'react'
import './styles.scss'

const ImagePreview = ({ image, metadata }) => {
  const images = {
    placeholder: require('../../../assets/images/placeholder.png'),
    front: require('../../../assets/images/front-dni.jpg'),
    back: require('../../../assets/images/back-dni.png'),
    selfie: require('../../../assets/images/selfie.png')
  }

  const renderPlaceholder = (metadata) => {
    const placeholder = metadata ? images[metadata.avatar] : images.placeholder;
    return (
      <img className="ImagePlaceholder" src={placeholder} alt="error"/>
    )
  }

  const renderImage = (image) => {
    return (
      <img className="ImagePlaceholder" src={image} width={700} height={500} alt="error"/>
    )
  }

  return (
    <div>
      {image ? renderImage(image) : renderPlaceholder(metadata)}
    </div>
  )
}

export default ImagePreview
