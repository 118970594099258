import * as actionsTypes from '../actionsTypes'

export const setOption = (option) => ({
  type: actionsTypes.SET_OPTION,
  payload: option
})

export const setImage = (data) => ({
  type: actionsTypes.SET_IMAGE,
  payload: data
})

export const setValidity = (validity) => ({
  type: actionsTypes.SET_VALIDITY,
  payload: validity
})
