import React from 'react'
import './styles.scss'

export const Loader = ({ isLoading }) => {
  const renderLoader = () => {
    return (
      <div className="LoaderContainer">
        <div className="Loader"/>
        <p className="Title"> Cargando ...</p>
      </div>
    )
  }

  return (
    <div>
      {isLoading ? renderLoader() : null}
    </div>
  )
}
