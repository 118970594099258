import React from 'react'
import CommonBody from '../../components/CommonBody'
import './styles.scss'

const HelpPage = () => {
  return (
    <CommonBody>
      <h1 className="Title">Preguntas frecuentes</h1>

      <div className="Question">
        <p className="Subtitle2">¿Qué hacer en caso de no recibir el enlace de registro?</p>
        <p className="Answer">
          En caso de que no hayas recibido el email o el mensaje de confirmación de registro por favor ponerse en
          contacto con soporte en <a href="mailto:cursosconvivenciamed@udea.edu.co">cursosconvivenciamed@udea.edu.co</a>
        </p>
      </div>

      <div className="Question">
        <p className="Subtitle2">¿Debo hacer un curso por cada infracción que tenga?</p>
        <p className="Answer">
          No, ver el curso una sola vez es suficiente para condonar todas las infracciones que tengas registradas.
        </p>
      </div>
    </CommonBody>
  )
}

export default HelpPage;
