import React from 'react'
import './styles.scss'

const RenderErrorMessage = ({ message }) => {
  return (
    <div className="ResponseContainer Error">
      <p className="ResponseText">{message.toString()}</p>
    </div>
  )
}

const RenderSuccessMessage = ({ message }) => {
  return (
    <div className="ResponseContainer Success">
      <p className="ResponseText">{message.toString()}</p>
    </div>
  )
}

export const MessagesViewer = ({ info }) => {
  return <div className="ResponseContainer">
    {info.message && info.success ? <RenderSuccessMessage message={info.message}/> : null}
    {info.message && !info.success ? <RenderErrorMessage message={info.message}/> : null}
  </div>
}
