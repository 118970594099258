import { GENERAL_FAIL_MESSAGE, postString } from '../../../http/httpClient'
import * as actionsTypes from '../actionsTypes'

export const forgetPassword = (values, token) => (dispatch) => {
  dispatch(setForgetLoading(true))
  postString(`people/forget/${values.dni}/${values.email}`)
    .then(response => {
      const message = `A su correo electrónico ${values.email} se ha enviado un enlace para continuar con el proceso`
      dispatch(setForgetInfo({ success: true, message: message }))
    })
    .catch(() => {
      dispatch(setForgetInfo({ success: false, message: GENERAL_FAIL_MESSAGE }))
    })
}

export const setForgetLoading = () => ({
  type: actionsTypes.SET_FORGET_LOADING
})

export const setForgetInfo = (info) => ({
  type: actionsTypes.SET_FORGET_INFO,
  payload: info
})
