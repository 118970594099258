import { SERVER_URL } from '../utils/constants'

export const GENERAL_FAIL_MESSAGE = 'Ha ocurrido un error mientras se completaba el proceso, por favor inténtelo de nuevo'
export const GENERAL_SUCCESS_MESSAGE = 'La operación ha sido completada de manera exitosa'

const readUrl = (url = '') => {
  return url.startsWith('http://') || url.startsWith('https://') ? url : `${SERVER_URL}${url}`
}

export const get = (url = '', headers = {}) =>
  fetch(readUrl(url), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    }
  }).then(response => response)
    .catch(error => error)

export const getJson = (url = '', headers = {}) =>
  fetch(readUrl(url), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    }
  }).then(response => response.json())
    .catch(error => error)
    .then(response => response)

export const getBlob = (url = '') =>
  fetch(readUrl(url), {
    method: 'GET',
  }).then(response => response.blob())
    .catch(error => error)
    .then(response => response)

export const postJson = (url = '', body = {}, headers = {}) =>
  fetch(readUrl(url), {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    }
  }).then(response => response.json())
    .catch(error => error)
    .then(response => response)

export const postString = (url = '', body = {}) =>
  fetch(readUrl(url), {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json;charset=UTF-8',
      'Content-Type': 'application/json',
    }
  }).then(response => response.text())
    .catch(error => error)
    .then(response => response)

export const postPure = (url = '', body = {}, headers = {}) => {
  const options = {
    method: 'POST',
    body: body,
    headers: {
      Accept: 'application/json',
      ...headers
    }
  }
  if (options && options.headers) {
    delete options.headers['Content-Type']
  }
  return fetch(url, options)
    .then(response => response.json())
    .catch(error => error)
    .then(response => response)

}

export const put = (url = '', body = {}, headers = {}) =>
  fetch(readUrl(url), {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      ...headers
    }
  }).then(response => response.json())
    .catch(error => error)
    .then(response => response)

export const del = (url = '', headers = {}) =>
  fetch(readUrl(url), {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    }
  }).then(response => response.json())
    .catch(error => error)
    .then(response => response)



