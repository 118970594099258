import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
  token: null
}

export const recaptchaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.SET_RECAPTCHA_TOKEN:
      return { ...state, token: action.payload }

    default:
      return state
  }
}
