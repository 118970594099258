import React from 'react'
import './styles.scss'
import RegisterForm from './RegisterForm'
import PoliciesForm from './PoliciesForm'
import { connect } from 'react-redux'
import { Loader } from '../../components/Loader'
import { MessagesViewer } from '../../components/Messages'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { setRegisterInfo } from '../../redux/actions/ActionsCreators/registerActionsCreator'

const HomePage = ({ registerState }) => {
  const handleAcceptClick = () => {
    window.location.reload(false)
    setRegisterInfo({ success: false, message: null })
  }

  const renderRegisterForm = (step) => {
    return (
      <div className="RegisterContainer">
        <h1 className="Title">Registra tus datos</h1>
        <p className="Text">
          Para iniciar con el proceso, el primer paso será ingresar tus datos de identidad y de contacto. <br/>
          Por favor llena todos los campos solicitados en el siguiente formulario.
        </p>

        {step === 1 ? <RegisterForm/> : null}
        {step > 1 ? <p className="Subtitle3 Completed">Completado</p> : null}
      </div>
    )
  }

  const renderPoliciesForm = (step) => {
    return (
      <div className="TermsContainer">
        <h1 className="Title">Acepta nuestra política de datos</h1>
        <p className="Text">
          El siguiente paso es aceptar nuestros términos y condiciones y nuestra política de trato de datos.
        </p>
        {step === 2 ? <PoliciesForm/> : null}
        {step > 2 ? <p className="Subtitle3 Completed">Completado</p> : null}
      </div>
    )
  }

  const renderConfirmation = (user) => {
    return (
      <div className="ConfirmationContainer">
        <h1 className="Title">Registro exitoso</h1>

        <div className="SuccessRegisterContainer">
          <b>Señor (a).</b>
          <p>Su registro ha sido completado de manera exitosa. <br/>
            A su correo <b>{user.email}</b> se ha enviado un enlace para continuar con el proceso.</p>
        </div>
      </div>
    )
  }

  const renderResetButton = () => {
    return (
      <div className="AcceptButton">
        <button className="BtnConfirm" onClick={handleAcceptClick}>Aceptar</button>
      </div>
    )
  }

  return (
    <div className="HomeContainer">
      <Navbar showHeader={true} showHome={false}/>
      <Loader isLoading={registerState.isLoading}/>

      <div className="FormsContainer">
        {registerState.step >= 1 ? renderRegisterForm(registerState.step) : null}
        {registerState.step >= 2 ? renderPoliciesForm(registerState.step) : null}
        {registerState.step >= 3 ? renderConfirmation(registerState.user) : null}
        {registerState.step >= 3 ? renderResetButton() : null}
        <MessagesViewer info={registerState.info}/>
      </div>

      <Footer/>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    registerState: state.register
  }
}

export default connect(mapStateToProps, null)(HomePage)
