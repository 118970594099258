import { GENERAL_FAIL_MESSAGE, postString } from '../../../http/httpClient'
import * as actionsTypes from '../actionsTypes'

export const authenticateUSer = (username, password) => (dispatch) => {
  dispatch(setAuthLoading(true))
  const credential = { username: username, password: password }
  postString('login', credential)
    .then(response => {
      if (response === 'UNAUTHORIZED') {
        const authMessage = 'Las credenciales de ingreso no son válidas'
        dispatch(setAuthInfo({ success: false, message: authMessage }))
      } else {
        dispatch(setAuthInfo({ success: false, message: null }))
        dispatch(setAuthToken(response))
      }
    })
    .catch(() => {
      dispatch(setAuthInfo({ success: false, message: GENERAL_FAIL_MESSAGE }))
    })
}

export const restorePassword = (dni) => (dispatch) => {
  dispatch(setAuthLoading(true))
  postString(`people/forget/${dni}`)
    .then(response => {
      dispatch(setAuthInfo({ success: false, message: response }))
    })
    .catch(() => {
      dispatch(setAuthInfo({ success: false, message: GENERAL_FAIL_MESSAGE }))
    })
}

export const setAuthLoading = () => ({
  type: actionsTypes.SET_AUTH_LOADING
})

export const setAuthToken = (response) => ({
  type: actionsTypes.SET_AUTH_TOKEN,
  payload: response
})

export const setAuthInfo = (info) => ({
  type: actionsTypes.SET_AUTH_INFO,
  payload: info
})
