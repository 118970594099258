import CommonBody from '../../components/CommonBody'
import React, { useEffect, useState } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import VerifyForm from './VerifyForm'
import { MessagesViewer } from '../../components/Messages'
import { getCertificateVerify, setCertificateFile } from '../../redux/actions/ActionsCreators/verifyActionsCreator'

const VerifyPage = ({ verifyState, recaptchaState, getCertificateVerify }) => {
  const { dni, certificate } = useParams()
  const [option, setOption] = useState(1)

  useEffect(() => {
    if (dni && certificate && !verifyState.certificate) {
      setOption(2)
      getCertificateVerify({ dni, certificate }, recaptchaState.token)
    }

  }, [dni, certificate, verifyState.certificate, recaptchaState.token, getCertificateVerify])

  const handleDownloadClick = () => {
    const a = document.createElement('a')
    a.href = verifyState.certificate
    a.download = 'certificado.pdf'
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  const renderHeaderDescription = (option) => {
    if (option === 1) {
      return <p>
        En esta sección puedes verificar la validez de un certificado. Por favor ingresa los datos asociados a
        dicho certificado en los siguientes campos, una vez presionado el botón "VERIFICAR" se
        realizará un proceso de validación en nuestro sistema.
      </p>
    }
  }

  const renderCertificatePdf = (certificateBlob) => {
    if (certificateBlob) {
      return <div className="IframeContainer">
        <iframe className="IframeContent" title="Certificado"
                src={certificateBlob} frameBorder="0" width="100%" height="850px"/>
        <p className="IframeContainerMobile">
          El certificado es válido, por favor haga clic sobre el botón "DESCARGAR" para poder visualizarlo.
        </p>
        <button className="BtnConfirm" onClick={handleDownloadClick}>Descargar</button>
      </div>
    }
  }

  return (
    <CommonBody isLoading={verifyState.isLoading}>
      <div className="VerifyContent">
        <div className="VerifyInfo">
          <h1 className="Title">Verificar certificado</h1>
          {renderHeaderDescription(option)}
        </div>

        <div className="">
          <div className="Col VerifyForm">
            {option === 1 ? <VerifyForm/> : null}
            <MessagesViewer info={verifyState.info}/>
          </div>

          <div className="Col VerifyResult">
            {renderCertificatePdf(verifyState.certificate)}
          </div>
        </div>
      </div>
    </CommonBody>
  )
}

const mapStateToProps = state => {
  return {
    verifyState: state.verify,
    recaptchaState: state.recaptcha
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCertificateVerify: (values, token) => dispatch(getCertificateVerify(values, token)),
  setCertificateFile: (content) => dispatch(setCertificateFile(content))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPage)
