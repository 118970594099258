import React from 'react'
import './styles.scss'
import { maxLength, minLength, required } from '../../../utils/validators'
import { Control, Errors, Form } from 'react-redux-form'
import { connect } from 'react-redux'
import {
  getCertificateVerify,
  setCertificateFile,
  setVerifyInfo
} from '../../../redux/actions/ActionsCreators/verifyActionsCreator'
import Recaptcha from '../../../components/Recaptcha'
import { trimString } from '../../../utils/Strings'

const VerifyForm = ({ recaptchaState, getCertificateVerify, setVerifyInfo, setCertificateFile }) => {

  const handleSubmit = (values) => {
    const verifyInfo = { ...values, dni: trimString(values.dni), certificate: trimString(values.certificate) }
    getCertificateVerify(verifyInfo, recaptchaState.token)
  }

  const handleReset = () => {
    setVerifyInfo({ success: null, message: null })
    setCertificateFile(null)
  }

  return (
    <Form onSubmit={(values) => handleSubmit(values)} model="verifyForm" className="Form">
      <div className="Cols3">
        <div className="Col InputContainer">
          <label htmlFor="dni">Número de documento</label>
          <Control.text model="verifyForm.dni" className="Input" name="dni" id="dni" placeholder="1234567890"
                        validators={{ required, minLength: minLength(6), maxLength: maxLength(12) }}/>
          <Errors className="TextError" model="verifyForm.dni" show="touched"
                  messages={{
                    required: 'El campo es requerido. ',
                    minLength: 'El campo debe contener al menos 6 dígitos. ',
                    maxLength: 'El campo debe contener como máximo 12 dígitos. '
                  }}/>
        </div>

        <div className="Col InputContainer">
          <label htmlFor="dni">Número de certificado</label>
          <Control.text model="verifyForm.certificate" className="Input" name="certificate" id="certificate"
                        placeholder="Ab97jH12786"
                        validators={{ required, minLength: minLength(8), maxLength: maxLength(12) }}/>
          <Errors className="TextError" model="verifyForm.certificate" show="touched"
                  messages={{
                    required: 'El campo es requerido. ',
                    minLength: 'El campo debe contener al menos 8 caracteres. ',
                    maxLength: 'El campo debe contener como máximo 12 caracteres. '
                  }}/>
        </div>

        <div className="Col Buttons">
          <Control.button model="verifyForm" type="reset" className="BtnAdvice" onClick={handleReset}>
            Restaurar
          </Control.button>
          <Control.button model="verifyForm" type="submit" className="BtnConfirm">Verificar</Control.button>
        </div>
      </div>
      <Recaptcha/>
    </Form>
  )
}

const mapStateToProps = state => {
  return { recaptchaState: state.recaptcha }
}

const mapDispatchToProps = (dispatch) => ({
  getCertificateVerify: (values, token) => dispatch(getCertificateVerify(values, token)),
  setVerifyInfo: (info) => dispatch(setVerifyInfo(info)),
  setCertificateFile: (file) => dispatch(setCertificateFile(file))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyForm)
