import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo'
import React, { useState } from 'react'
import './styles.scss'

const OnlineCamera = ({ onSnapshot, onError }) => {
  const [dataUri] = useState('')

  const handleTakePhoto = (dataUri) => {
    onSnapshot(dataUri)
  }

  const handleCameraError = (error) => {
    onError(error)
  }

  return (
    <div>
      {(dataUri)
        ? null
        : <Camera
          onTakePhoto={(dataUri) => { handleTakePhoto(dataUri) }}
          onCameraError={(error) => { handleCameraError(error) }}
          imageType={IMAGE_TYPES.PNG}
          idealResolution={{ width: 500, height: 320 }}
          idealFacingMode={FACING_MODES.USER}
        />
      }
    </div>
  )
}

export default OnlineCamera
