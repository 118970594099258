import React, { useEffect } from 'react'
import './styles.scss'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { authenticateUSer } from '../../redux/actions/ActionsCreators/authActionsCreator'
import ImageLoader from './ImageLoader'
import {
  getUserInfo,
  getUserPenalties,
  putFinishRegister,
  setUpdateStep
} from '../../redux/actions/ActionsCreators/updateActionsCreators'
import { MessagesViewer } from '../../components/Messages'
import UpdateForm from './UpdateForm'
import CommonBody from '../../components/CommonBody'
import { anonymizeString } from '../../utils/Strings'

const RegisterPage = ({
  authState,
  updateState,
  authenticateUser,
  getUserInfo,
  getUserPenalties,
  setUpdateStep,
  putFinishRegister
}) => {
  const { username, password } = useParams()

  const getStepFromUserState = (state) => {
    const stateStepEquivalences = [
      { state: 'WAITING_PERSONAL_INFO', step: 1 },
      { state: 'WAITING_FRONT_DNI', step: 2 },
      { state: 'WAITING_BACK_DNI', step: 3 },
      { state: 'WAITING_SELFIE', step: 4 },
      { state: 'PENDING_FINISH', step: 5 },
      { state: 'AVAILABLE', step: 6 }
    ]

    return stateStepEquivalences.filter(item => item.state === state)[0].step
  }

  useEffect(() => {
    if (!authState.token) authenticateUser(username, password)
    if (authState.token && !updateState.user) getUserInfo(authState.token)
    if (updateState.user) getUserPenalties(updateState.user.dni)
    if (updateState.user) setUpdateStep(getStepFromUserState(updateState.user.registerState))
  }, [
    username,
    password,
    authState.token,
    updateState.user,
    authenticateUser,
    getUserInfo,
    getUserPenalties,
    setUpdateStep
  ])

  const handleFinishRegister = () => {
    putFinishRegister(authState.token)
  }

  const renderUserInfo = (user) => {
    return (
      <div className="UserInfo">
        <h1 className="Subtitle2">Identificación</h1>
        <p>
          <b>Tipo de documento:</b> {anonymizeString(user.documentType)} <br/>
          <b>Número de documento:</b> {anonymizeString(user.dni)} <br/>
          <b>Fecha de expedición:</b> {anonymizeString(new Date(user.expeditionDniDate).toLocaleDateString())} <br/>
          <b>Lugar de expedición:</b> {anonymizeString(user.expeditionDniPlace)} <br/>
        </p>
      </div>
    )
  }

  const renderUserPenalties = (penalties) => {
    return (
      <div className="UserPenalties">
        <h1 className="Subtitle2">Comparendos condonables</h1>
        {penalties.map(penalty => (
          <div key={penalty.expedient} className="Penalty">
            <p>
              <b>Expediente:</b> {penalty.expedient} <br/>
              <b>Fecha límite:</b> {penalty.limitDate} <br/>
              <b>Artículo:</b> {penalty.article} <br/>
              <b>Comportamiento:</b> {penalty.behavior} <br/>
            </p>
          </div>
        ))}
      </div>
    )
  }

  const renderUpdateInfoForm = (step) => {
    return (
      <div className="UpdateForm">
        <h1 className="Subtitle2">Paso 1/5. Ingresa tus datos personales</h1>
        <p className="Text">
          Todos los campos marcados con * son obligatorios.
        </p>

        {step === 1 ? <UpdateForm/> : null}
        {step > 1 ? <p className="Subtitle3 Completed">Completado</p> : null}
      </div>
    )
  }

  const renderFrontDniImageForm = (step) => {
    return (
      <div className="UpdateForm">
        <h1 className="Subtitle2">Paso 2/5. Foto del documento de identidad por el lado frontal</h1>
        <p className="Text">
          Carga una foto de tu documento de identidad / pasaporte por el lado frontal, por favor intenta evitar
          que la foto quede desenfocada o con mucho brillo. Puedes cargarla desde tu dispositivo o
          tomar una nueva con la cámara.
        </p>

        {step === 2 ? <ImageLoader/> : null}
        {step > 2 ? <p className="Subtitle3 Completed">Completado</p> : null}
      </div>
    )
  }

  const renderBackDniImageForm = (step) => {
    return (
      <div className="UpdateForm">
        <h1 className="Subtitle2">Paso 3/5. Foto de la cédula por lado de atrás</h1>
        <p className="Text">
          Carga una foto de tu cédula por el lado trasero, por favor intenta evitar que la foto quede
          desenfocada o con mucho brillo. Puedes cargarla desde tu dispositivo o tomar una nueva con la cámara.
        </p>

        {step === 3 ? <ImageLoader/> : null}
        {step > 3 ? <p className="Subtitle3 Completed">Completado</p> : null}
      </div>
    )
  }

  const renderSelfieImageForm = (step) => {
    return (
      <div className="UpdateForm">
        <h1 className="Subtitle2">Paso 4/5. Foto tipo selfie</h1>
        <p className="Text">
          Carga una foto donde se vea claramente tu rostro, por favor intenta evitar que la foto quede
          desenfocada o con mucho brillo. Puedes cargarla desde tu dispositivo o tomar una nueva con la cámara.
        </p>

        {step === 4 ? <ImageLoader/> : null}
        {step > 4 ? <p className="Subtitle3 Completed">Completado</p> : null}
      </div>
    )
  }

  const renderConfirmation = (step) => {
    return (
      <div className="UpdateForm">
        <h1 className="Subtitle2">Paso 5/5. Finalizar</h1>
        <p className="Text">
          Para completar el proceso por favor presiona click sobre el botón <b>Finalizar</b>.
          A su correo electrónico {updateState.user.email} será enviado un enlace para ingresar a los contenidos
          del curso. En caso de no recibir el correo ponerse en contacto con
          <a href="mailto:cursosconvivenciamed@udea.edu.co"> cursosconvivenciamed@udea.edu.co</a>
        </p>

        {step === 5 ? renderFinishButton() : null}
        {step > 5 ? <p className="Subtitle3 Completed">Completado</p> : null}
      </div>
    )
  }

  const renderFinishButton = () => {
    return (
      <div className="AcceptButton">
        <button className="BtnConfirm" onClick={handleFinishRegister}>Finalizar</button>
      </div>
    )
  }

  return (
    <CommonBody isLoading={updateState.isLoading || authState.isLoading}>
      <div className="UpdateContent">
        <h1 className="Title">Actualización de datos personales</h1>
        <p className="Text">
          Con el fin de verificar la identidad del aspirante a tomar el curso, se le estará solicitando en los
          siguientes pasos algunos datos personales, por favor complete los siguientes cinco pasos para validar
          su identidad.
        </p>
        <MessagesViewer info={authState.info}/>
        {updateState.user ? renderUserInfo(updateState.user) : null}
        {updateState.user ? renderUserPenalties(updateState.penalties) : null}
        {updateState.step >= 1 ? renderUpdateInfoForm(updateState.step) : null}
        {updateState.step >= 2 ? renderFrontDniImageForm(updateState.step) : null}
        {updateState.step >= 3 ? renderBackDniImageForm(updateState.step) : null}
        {updateState.step >= 4 ? renderSelfieImageForm(updateState.step) : null}
        {updateState.step >= 5 ? renderConfirmation(updateState.step) : null}
        <MessagesViewer info={updateState.info}/>
      </div>
    </CommonBody>
  )
}

const mapStateToProps = state => {
  return {
    authState: state.auth,
    updateState: state.update,
    imageState: state.image
  }
}

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (username, password) => dispatch(authenticateUSer(username, password)),
  getUserInfo: (token) => dispatch(getUserInfo(token)),
  getUserPenalties: (dni) => dispatch(getUserPenalties(dni)),
  putFinishRegister: (token) => dispatch(putFinishRegister(token)),
  setUpdateStep: (step) => dispatch(setUpdateStep(step))
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
