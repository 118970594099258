import * as actionsTypes from '../actionsTypes'
import { get } from '../../../http/httpClient'
import { PENALTIES_URL } from '../../../utils/constants'

export const getCertificateVerify = (values, token) => (dispatch) => {
  dispatch(setVerifyLoading(true))
  dispatch(setCertificateFile(null))
  get(`${PENALTIES_URL}certificates/people/${values.dni}/code/${values.certificate}`)
    .then((response) => {
      if (response.ok) {
        response.blob().then(blob => {
          const url = window.URL.createObjectURL(blob)
          dispatch(setCertificateFile(url))
        })
      } else {
        response.json().then(error => {
          dispatch(setVerifyInfo({ success: false, message: error.content }))
        })
      }
    }).catch(error => {
    dispatch(setVerifyInfo({ success: false, message: error }))
  })
}

export const setVerifyInfo = (response) => ({
  type: actionsTypes.SET_VERIFY_INFO,
  payload: response
})

export const setVerifyLoading = (loading) => ({
  type: actionsTypes.SET_VERIFY_LOADING,
  payload: loading
})

export const setCertificateFile = (response) => ({
  type: actionsTypes.SET_CERTIFICATE_FILE,
  payload: response
})
