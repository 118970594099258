import { GENERAL_FAIL_MESSAGE, getJson, postJson } from '../../../http/httpClient'
import * as actionsTypes from '../actionsTypes'
import { PENALTIES_URL } from '../../../utils/constants'

export const getDataPolicies = () => (dispatch) => {
  dispatch(setRegisterLoading(true))
  getJson('data-policies')
    .then(response => {
      if (response && response.length) {
        dispatch(setPolicies(response))
      } else {
        const policiesNotFound = 'Ha ocurrido un error mientras se obtenían las políticas de datos'
        dispatch(setRegisterInfo({ success: false, message: policiesNotFound }))
      }
    })
    .catch((error) => {
      dispatch(setRegisterInfo({ success: false, message: error.toString() }))
    })
}

export const postUser = (user) => (dispatch) => {
  dispatch(setRegisterLoading(true))
  getJson(`${PENALTIES_URL}people/active/${user.dni}`)
    .then(response => {
      if (response.success && response.content) {
        postJson('people/register', user)
          .then(response => {
            if (response && response.message) {
              dispatch(setRegisterInfo({ success: false, message: response.message }))
            } else {
              dispatch(setUserInfo(response))
              dispatch(setRegisterStep(3))
            }
          })
          .catch(() => {
            dispatch(setRegisterInfo({ success: false, message: GENERAL_FAIL_MESSAGE }))
          })
      } else {
        const userNotFound = 'El usuario no cuenta con comparendos activos en el sistema'
        dispatch(setRegisterInfo({ success: false, message: userNotFound }))
      }
    }).catch(() => {
    dispatch(setRegisterInfo({ success: false, message: GENERAL_FAIL_MESSAGE }))
  })
}

export const setUserInfo = (user) => ({
  type: actionsTypes.SET_USER_INFO,
  payload: user
})

export const setRegisterStep = (step) => ({
  type: actionsTypes.SET_REGISTER_STEP,
  payload: step
})

export const setRegisterLoading = (loading) => ({
  type: actionsTypes.SET_REGISTER_LOADING,
  payload: loading
})

export const setPolicies = (response) => ({
  type: actionsTypes.SET_POLICIES,
  payload: response
})

export const setRegisterInfo = (info) => ({
  type: actionsTypes.SET_REGISTER_INFO,
  payload: info
})
