import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
  isLoading: false,
  info: { success: false, message: null },
  token: null
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.SET_AUTH_LOADING:
      return { ...state, isLoading: action.payload }

    case actionsTypes.SET_AUTH_TOKEN:
      return { ...state, isLoading: false, token: action.payload }

    case actionsTypes.SET_AUTH_INFO:
      return { ...state, isLoading: false, info: { success: action.payload.success, message: action.payload.message } }

    default:
      return state
  }
}
