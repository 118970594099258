export const required = (value) => value && value.length
export const maxLength = (len) => (value) => !(value) || (value.length <= len)
export const minLength = (len) => (value) => value && (value.length >= len)
export const isNumber = (value) => !isNaN(Number(value))
export const validEmail = (value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
export const matchEmail = (email) => (value) => value === email
export const dateLower = (date) => new Date(date).getTime() <= new Date().getTime()
export const dateGreaterThan = (years) => (date) => {
  const yearMilliseconds = 1000 * 60 * 60 * 24 * 365.25
  return new Date(date).getTime() <= new Date().getTime() - (yearMilliseconds * (years - 1))
}


