import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
  option: 1,
  image: null,
  equivalences: [
    {
      status: 'WAITING_FRONT_DNI',
      avatar: 'front',
      recognition: 'id_frontal',
      server: 'FRONT_DNI',
    },
    {
      status: 'WAITING_BACK_DNI',
      avatar: 'back',
      recognition: 'id_anverso',
      server: 'BACK_DNI',
    },
    {
      status: 'WAITING_SELFIE',
      avatar: 'selfie',
      recognition: 'selfie',
      server: 'SELFIE',
    }
  ]
}

export const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.SET_OPTION:
      return { ...state, option: action.payload }

    case actionsTypes.SET_IMAGE:
      return { ...state, image: action.payload }

    case actionsTypes.SET_VALIDITY:
      return { ...state, validity: action.payload }

    default:
      return state
  }
}
