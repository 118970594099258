import { GENERAL_FAIL_MESSAGE, getJson, postPure, put } from '../../../http/httpClient'
import * as actionsTypes from '../actionsTypes'
import { PENALTIES_URL, RECOGNITION_URL } from '../../../utils/constants'
import { dataUriToBlob } from '../../../utils/files'

export const getUserInfo = (token) => (dispatch) => {
  dispatch(setUpdateLoading(true))
  const headers = { Authorization: `Bearer ${token}` }
  getJson('people/info', headers)
    .then(response => {
      dispatch(setUserInfo(response))
    })
    .catch(error => {
      dispatch(setUpdateInfo({ success: false, message: error }))
    })
}

export const getUserPenalties = (dni) => (dispatch) => {
  dispatch(setUpdateLoading(true))
  getJson(`${PENALTIES_URL}penalties/byPersonDni/${dni}`)
    .then(response => {
      if (response.success && response.content) {
        dispatch(setUserPenalties(response.content))
      } else {
        dispatch(setUpdateInfo({ success: false, message: response.message }))
      }
    })
    .catch(error => {
      dispatch(setUpdateInfo({ success: false, message: error }))
    })
}

export const putUserInfo = (user, token) => (dispatch) => {
  dispatch(setUpdateLoading(true))
  const headers = { Authorization: `Bearer ${token}` }
  put('people/register/personal-info', user, headers)
    .then(response => {
      dispatch(setUserInfo(response))
      dispatch(setUpdateStep(2))
    })
    .catch(error => {
      dispatch(setUpdateInfo({ success: false, message: error }))
    })
}

export const postUserImage = (dni, image, token, imageInfo) => (dispatch) => {
  dispatch(setUpdateLoading(true))
  const file = dataUriToBlob(image)
  const fileOfBlob = new File([file], `${imageInfo.recognition}.png`)
  const formData = new FormData()
  formData.append('images[]', fileOfBlob)

  const url = `${RECOGNITION_URL}${imageInfo.recognition}?id=${dni}`
  postPure(url, formData)
    .then(response => {
      dispatch(setUpdateLoading(false))
      if (!response.error) {
        dispatch(putUserFileServer(response.url, token, imageInfo))
      } else {
        dispatch(setUpdateInfo({ success: false, message: response.error }))
      }
    })
    .catch(error => {
      dispatch(setUpdateInfo({ success: false, message: error }))
    })
}

export const putUserFileServer = (fileUrl, token, imageInfo) => (dispatch) => {
  dispatch(setUpdateLoading(true))
  const body = {
    type: 'PHOTO',
    id: imageInfo.server,
    url: fileUrl,
    createdDate: new Date()
  }

  const headers = { Authorization: `Bearer ${token}` }
  put('people/register/resources', body, headers)
    .then(response => {
      dispatch(setUserInfo(response))
    })
    .catch(error => {
      dispatch(setUpdateInfo({ success: false, message: error }))
    })
}

export const putFinishRegister = (token) => (dispatch) => {
  dispatch(setUpdateLoading(true))
  const headers = { Authorization: `Bearer ${token}` }
  put('people/register/finish', null, headers)
    .then(response => {
      if (response.registerState && response.registerState === 'AVAILABLE') {
        dispatch(setUserInfo(response))
        const successMessage = 'Su proceso de inscripción ha sido completado de manera exitosa. ' +
          'A su correo electrónico ha sido enviado un enlace para ingresar a los contenidos del curso. ' +
          'A partir de ahora esta página quedará deshabilitada y no podrá volver a ser accedida.'
        dispatch(setUpdateInfo({ success: true, message: successMessage }))
        setTimeout(() => {
          window.location = '/'
        }, 5000)
      } else {
        dispatch(setUpdateInfo({ success: false, message: GENERAL_FAIL_MESSAGE }))
      }
    })
    .catch(error => {
      dispatch(setUpdateInfo({ success: false, message: error }))
    })
}

export const setUpdateStep = (step) => ({
  type: actionsTypes.SET_UPDATE_STEP,
  payload: step
})

export const setUserInfo = (response) => ({
  type: actionsTypes.SET_USER_INFO,
  payload: response
})

export const setUserPenalties = (penalties) => ({
  type: actionsTypes.SET_USER_PENALTIES,
  payload: penalties
})

export const setUpdateLoading = (loading) => ({
  type: actionsTypes.SET_UPDATE_LOADING,
  payload: loading
})

export const setUpdateInfo = (info) => ({
  type: actionsTypes.SET_UPDATE_INFO,
  payload: info
})

