import React from 'react'
import './styles.scss'

const Footer = () => {
  const getYear = () => {
    return new Date().getFullYear()
  }

  return (
    <div className="FooterContainer">
      <p>Medellín - Colombia | Todos los Derechos Reservados © {getYear()} v026</p>
    </div>
  )
}

export default Footer
