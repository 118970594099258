import React from 'react'
import './styles.scss'
import { Link } from 'react-router-dom'
import logoMedellin from '../../assets/images/logo-alcaldia.png'
import logoMini from '../../assets/images/logo-alcaldia-mini.png'

const Navbar = ({ showHeader, showHome }) => {
  const renderHeader = () => {
    return (
      <header className="HeaderContainer BackGround1">
        <div className="TitleContainer">
          <h1 className="BigTitle">Inscripción al Curso virtual</h1>
          <h2 className="Title"> Programa Pedagógico de <br/> Convivencia</h2>
        </div>
        <div className="HeaderImageContainer">
          <img className="HeaderImage" src={logoMedellin} alt="Escudo de la Alcaldía"
               title="Alcaldía de Medellín"/>
        </div>
      </header>
    )
  }

  const renderNavbarImage = () => {
    return (
      <div className="ImageContainer">
        <img className="NavbarImage" src={logoMini} alt="Escudo de la Alcaldía"
             title="Alcaldía de Medellín"/>
      </div>
    )
  }

  const renderHomeNavbar = () => {
    return (
      <div className="LinksContainer">
        <Link to="/verify" className="Item Items3">Verificar</Link>
        <Link to="/forget" className="Item Items3">Recuperar</Link>
        <Link to="/help" className="Item Items3">Ayuda</Link>
      </div>
    )
  }

  const renderCommonNavbar = () => {
    return (
      <div className="LinksContainer">
        <Link to="/" className="Item Items4">Inicio</Link>
        <Link to="/verify" className="Item Items4">Verificar</Link>
        <Link to="/forget" className="Item Items4">Recuperar</Link>
        <Link to="/help" className="Item Items4">Ayuda</Link>
      </div>
    )
  }

  return (
    <div className="NavbarContainer">
      {showHeader ? renderHeader() : null}

      <div className="NavbarItems">
        <div className="NavbarImageContainer">
          {showHeader ? null : renderNavbarImage()}
        </div>

        {showHome ? renderCommonNavbar() : renderHomeNavbar()}
      </div>
    </div>
  )
}

export default Navbar;
