import CommonBody from '../../components/CommonBody'
import { MessagesViewer } from '../../components/Messages'
import React from 'react'
import ForgetForm from './ForgetForm'
import { connect } from 'react-redux'

const ForgetPage = ({ forgetState }) => {
  return (
    <CommonBody isLoading={forgetState.isLoading}>
      <div className="VerifyContent">
        <div className="VerifyInfo">
          <h1 className="Title">Recuperar enlace</h1>
          <p>
            En esta sección puedes recuperar tanto el enlace para completar el registro como para acceder al curso.
            Por favor ingresa los datos solicitados en los siguientes campos, una vez presionado
            el botón "RECUPERAR" se realizará un proceso de validación en nuestro sistema y será enviado a su correo
            electrónico el enlace asociado a su estado.
          </p>
        </div>

        <div className="">
          <div className="Col VerifyForm">
            <ForgetForm/>
            <MessagesViewer info={forgetState.info}/>
          </div>
        </div>
      </div>
    </CommonBody>
  )
}

const mapStateToProps = state => {
  return {
    forgetState: state.forget,
    recaptchaState: state.recaptcha
  }
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPage)
