import { applyMiddleware, combineReducers, createStore } from 'redux'
import { authReducer } from './reducers/authReducer'
import { registerReducer } from './reducers/registerReducer'
import { createForms } from 'react-redux-form'
import { updateReducer } from './reducers/updateReducer'
import { initialRegister } from './forms/register'
import thunk from 'redux-thunk'
import { initialUpdate } from './forms/update'
import { imageReducer } from './reducers/imageReducer'
import { verifyReducer } from './reducers/verifyReducer'
import { initialVerify } from './forms/verify'
import { recaptchaReducer } from './reducers/recaptchaReducer'
import { initialForget } from './forms/forget'
import { forgetReducer } from './reducers/forgetReducer'

export const ConfigureStore = () => {
  return createStore(
    combineReducers({
      auth: authReducer,
      register: registerReducer,
      update: updateReducer,
      verify: verifyReducer,
      forget: forgetReducer,
      image: imageReducer,
      recaptcha: recaptchaReducer,
      ...createForms({
        registerForm: initialRegister,
        updateForm: initialUpdate,
        verifyForm: initialVerify,
        forgetForm: initialForget
      })
    }),
    applyMiddleware(thunk)
  )
}
