import React from 'react'
import { HashRouter, Route } from 'react-router-dom'
import './index.scss'
import HomePage from './pages/HomePage'
import UpdatePage from './pages/UpdatePage'
import HelpPage from './pages/HelpPage'
import VerifyPage from './pages/VerifyPage'
import ForgetPage from './pages/ForgetPage'

const App = () => {
  return (
    <div className="MainContainer">
      <HashRouter>
        <Route path="/" component={HomePage} exact/>
        <Route path="/update/:username/:password" component={UpdatePage} exact/>
        <Route path="/verify/:dni?/:certificate?" component={VerifyPage} exact/>
        <Route path="/forget" component={ForgetPage} exact/>
        <Route path="/help" component={HelpPage} exact/>
      </HashRouter>
    </div>
  )
}

export default App
